@import "../../../mixins";

.case-card {
  position: relative;

  padding: rem(35);
  width: 100%;
  max-width: rem(454);
  display: flex;
  align-items: stretch;
  flex-direction: column;

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);
  overflow: hidden;

  transition: box-shadow var(--animation-default);

  @include mediaBigDesktop {
    padding: big(35);
    max-width: big(454);

    border-width: big(1);
  }

  @include mediaDesktop {
    padding: rem(30);
    max-width: rem(357);
  }

  @include mediaLaptop {
    padding: rem(20);
    max-width: rem(263);
  }

  @include mediaTablet {
    max-width: rem(334);
  }

  @include mediaMobile {
    padding: rem(15);
    max-width: rem(270);
  }

  @include hover {
    //box-shadow: var(--shadow-default);

    /*& .case-card__image img {
      transform: scale(1.1);
    }*/
  }

  &__top {
    position: relative;

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;

    border-radius: var(--radius-common) var(--radius-common) 0 0;
  }

  &__image {
    position: relative;
    z-index: 0;

    width: 100%;
    height: auto;
    aspect-ratio: 384/190;

    border-radius: var(--radius-secondary);
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    @supports not (aspect-ratio: 1/1) {
      height: rem(190);

      @include mediaBigDesktop {
        height: big(190);
      }
    }

    @include mediaDesktop {
      aspect-ratio: 299/160;

      @supports not (aspect-ratio: 1/1) {
        height: rem(160);
      }
    }

    @include mediaLaptop {
      aspect-ratio: 219/120;

      @supports not (aspect-ratio: 1/1) {
        height: rem(214);
      }
    }

    @include mediaTablet {
      aspect-ratio: 294/160;

      @supports not (aspect-ratio: 1/1) {
        height: rem(160);
      }
    }

    @include mediaMobile {
      aspect-ratio: 240/130;

      @supports not (aspect-ratio: 1/1) {
        height: rem(130);
      }
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      //height: 100%;

      background-color: var(--bg-light);
      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-medium);
    }
  }

  &__bottom {
    padding-top: rem(30);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(20);

    @include mediaBigDesktop {
      padding-top: big(30);
      gap: big(20);
    }

    @include mediaLaptop {
      padding-top: rem(20);
      gap: rem(15);
    }

    @include mediaTablet {
      padding-top: rem(25);
    }

    @include mediaMobile {
      padding-top: rem(20);
    }
  }

  &__content {
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaDesktop {
      gap: rem(10);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(8);

    @include mediaBigDesktop {
      gap: big(8);
    }

    @include mediaDesktop {
      gap: rem(5);
    }

    @include mediaLaptop {
      gap: rem(8);
    }
  }

  &__title,
  &__description {
    display: -webkit-box;

    color: var(--text-dark-primary);
    font: var(--font-body-L-sb);
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    text-overflow: ellipsis;

    overflow: hidden;

    @include mediaDesktop {
      font: 600 rem(18)/1.5 var(--font-primary);
    }

    @include mediaLaptop {
      font: 500 rem(16)/1.5 var(--font-primary);
    }

    @include mediaMobile {
      font: 500 rem(14)/1.5 var(--font-primary);
    }
  }

  &__title {
    transition: color var(--animation-default);

    @include hover {
      color: var(--accent-primary-default);
    }

    & .icon {
      position: relative;
      top: rem(3);

      fill: var(--accent-primary-default);

      @include mediaBigDesktop {
        top: big(3);
      }

      @include mediaLaptop {
        top: rem(5);
      }

      @include mediaTablet {
        top: rem(4);
      }
      @include mediaMobile {
        top: rem(3);
      }
    }
  }

  &__description {
    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
    -webkit-line-clamp: 2;

    @include mediaLaptop {
      -webkit-line-clamp: 3;
    }
  }

  &__solution {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    & .button {

      &__text,
      &__text [data-button-text]:before {
        text-decoration: underline;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-decoration-thickness: 1px;
      }
    }

    & .icon {
      width: rem(15);
      height: rem(15);
      flex-shrink: 0;

      fill: var(--accent-primary-default);

      @include mediaBigDesktop {
        width: big(15);
        height: big(15);
      }

      @include mediaLaptop {
        width: rem(14);
        height: rem(14);
      }

      @include mediaMobile {
        margin-top: rem(-2);
      }
    }

    /*& .text {
      width: 100%;
      display: block;

      color: var(--text-dark-secondary);
      font: 400 rem(20)/1.55 var(--font-primary);

      @include mediaBigDesktop {
        font: 400 big(20)/1.55 var(--font-primary);
      }

      @include mediaDesktop {
        font: 400 rem(16)/1.55 var(--font-primary);
      }

      @include mediaLaptop {
        font: 400 rem(14)/1.55 var(--font-primary);
      }

      @include mediaMobile {
        font: 400 rem(12)/1.55 var(--font-primary);
      }

      & a {
        position: relative;
        z-index: 2;

        color: var(--accent-primary-default);
        text-decoration: none;
        white-space: nowrap;

        transition: color var(--animation-default);

        @include hover {
          color: var(--text-dark-primary);

          &::before {
            right: 0;
            left: unset;

            width: 0;
          }
        }

        &::before {
          content: "";

          position: absolute;
          bottom: rem(3);
          left: 0;

          width: 100%;
          height: 1px;

          background-color: var(--accent-primary-default);

          transition: width var(--animation-default);
        }
      }
    }*/
  }

  & .tag-list {
    position: relative;
    z-index: 2;

    & a.tag {
      transition: color var(--animation-default);

      @include hover {
        color: var(--accent-primary-default);
      }
    }
  }
}
